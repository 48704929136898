(function () {
    "use strict";


    // @ngInject
    function PhotosUploadManagerCtor($q, $sce, $timeout, $,
                                     APIService, Routes, ModelFactory, Upload, AnalyticsService,
                                     Gon, moment, CloudinaryConfigService, OnboardingService, DatadogRUMService) {
        this.constructor.$super.call(this, APIService, ModelFactory, $q);

        this.Upload = Upload;
        this.CloudinaryConfigService = CloudinaryConfigService;
        this.Routes = Routes;
        this.$sce = $sce;
        this.$ = $;
        this.Gon = Gon;
        this.moment = moment;
        this.$timeout = $timeout;
        this.AnalyticsService = AnalyticsService;
        this.OnboardingService = OnboardingService;
        this.gettingNewAWSPolicyPromise = null;
        this.DatadogRUMService = DatadogRUMService;
    }


    Services.PhotosUploadManager = Class(Services.BaseModelManager, {

        constructor: PhotosUploadManagerCtor,

        _getSavePhotoArguments: function _getSavePhotoArguments(file, data, model) {
            var saveArguments = {};
            if (data) {
                saveArguments.url = data.secure_url;
                saveArguments.cloudinary_url = data.secure_url;
            }

            var buildRelativePath, hasRelativePath;

            hasRelativePath = function (file) {
                return file.relativePath || file.webkitRelativePath;
            };

            buildRelativePath = function (file) {
                return file.relativePath || (file.webkitRelativePath ? file.webkitRelativePath.split("/").slice(0, -1).join("/") + "/" : void 0);
            };

            saveArguments.filename = file.name;
            if ('size' in file) {
                saveArguments.filesize = file.size;
            }
            if ('type' in file) {
                saveArguments.filetype = file.type;
            }
            if ('unique_id' in file) {
                saveArguments.unique_id = file.unique_id;
            }
            if (hasRelativePath(file)) {
                saveArguments.relativePath = buildRelativePath(file);
            }

            var subAccountName = this.CloudinaryConfigService.getCloudinaryCloudName(this.CloudinaryConfigService.CONFIG_TYPE.USER_UPLOAD);

            saveArguments.cloudinary_public_id = data.public_id;
            saveArguments.cloudinary_sub_account = subAccountName;
            saveArguments.width = data.width;
            saveArguments.height = data.height;
            saveArguments.phash = data.phash;

            //this will make sure that the image is saved as
            if (model.type === 'icon' && model.saveIconAsLogoToo) {
                saveArguments.saveIconAsLogoToo = true;
            }

            return saveArguments;
        },

        _getSaveAssetArguments: function _getSaveAssetArguments(file, data, model) {
            var saveArguments = {};
            if (data) {
                saveArguments.url = this.$(data).find("Location").text();
            }

            var buildRelativePath, hasRelativePath;

            hasRelativePath = function (file) {
                return file.relativePath || file.webkitRelativePath;
            };

            buildRelativePath = function (file) {
                return file.relativePath || (file.webkitRelativePath ? file.webkitRelativePath.split("/").slice(0, -1).join("/") + "/" : void 0);
            };

            saveArguments.filename = file.name;
            if ('size' in file) {
                saveArguments.filesize = file.size;
            }
            if ('type' in file) {
                saveArguments.filetype = file.type;
            }
            if ('unique_id' in file) {
                saveArguments.unique_id = file.unique_id;
            }
            if (hasRelativePath(file)) {
                saveArguments.relativePath = buildRelativePath(file);
            }

            //this will make sure that the image is saved as
            if (model.type === 'icon' && model.saveIconAsLogoToo) {
                saveArguments.saveIconAsLogoToo = true;
            }

            return saveArguments;
        },

        getUpdatedModelData: function getUpdatedModelData(model) {
            switch (model.type) {
                case 'cover':
                    model.key = 'companies/' + model.instance._id + '/cover/${filename}';
                    model.saveURL = undefined; //not implemented
                    model.contentType = 'application/octet-stream';
                    model.acceptFilesFilter = 'image/*';
                    break;
                case 'logo':
                case 'icon':
                    model.key = 'company/' + model.instance._id + '/' + model.type + '/${filename}';
                    model.saveURL = this.Routes.v2_companies_save_image_path(model.instance._id, model.type);
                    model.allowMultiple = model.type === 'cover'; // only cover photos allowed multiple
                    model.acceptFilesFilter = 'image/*';
                    break;
                case 'profile_image':
                    model.key = 'user/' + model.instance._id + '/' + model.type + '/${filename}';
                    model.saveURL = this.Routes.v2_user_save_image_path(model.instance._id, model.type);
                    model.allowMultiple = false;
                    model.acceptFilesFilter = 'image/*';
                    break;
                case 'workspace_message':
                    model.key = 'workspaces/' + model.instance._id + '/message/' + new Date().getTime() + '/' + '${filename}';
                    model.saveURL = undefined; //not implemented
                    model.allowMultiple = true;
                    model.contentType = 'application/octet-stream';
                    model.acceptFilesFilter = '';
                    break;
                case 'workspace_attachment':
                    model.key = 'workspaces/' + model.instance._id + '/attachments/' + new Date().getTime() + '/' + '${filename}';
                    model.saveURL = undefined;
                    model.allowMultiple = true;
                    model.contentType = 'application/octet-stream';
                    break;
                case 'event_message':
                    model.key = 'events/' + model.instance._id + '/message/' + new Date().getTime() + '/' + '${filename}';
                    model.saveURL = undefined; //not implemented
                    model.allowMultiple = true;
                    model.contentType = 'application/octet-stream';
                    model.acceptFilesFilter = '';
                    break;
                case 'setup_asset':
                    model.key = 'companies/' + model.instance._id + '/setup_assets/' + new Date().getTime() + '-${filename}';
                    model.saveURL = undefined; //not implemented
                    model.allowMultiple = true;
                    model.contentType = 'application/octet-stream';
                    model.acceptFilesFilter = '';
                    break;
                case 'company_file':
                    model.key = 'companies/' + model.instance._id + '/assets/' + new Date().getTime() + '/' + '${filename}';
                    model.saveURL = undefined; //not implemented
                    model.allowMultiple = true;
                    model.contentType = 'application/octet-stream';
                    model.acceptFilesFilter = '';
                    break;
                case 'project_gallery_image':
                    model.key = 'network/project/' + model.instance._id + '/assets/' + new Date().getTime() + '/' + '${filename}';
                    model.saveURL = undefined; //not implemented
                    model.allowMultiple = true;
                    model.contentType = 'application/octet-stream';
                    model.acceptFilesFilter = 'image/*';
                    break;
                case 'user_identification_doc':
                    model.key = 'user/' + model.instance._id + '/' + model.type + '/' + new Date().getTime() + '-${filename}';
                    model.saveURL = this.Routes.v2_user_save_identificaion_url_path(model.instance._id, model.type);
                    model.allowMultiple = false;
                    model.acceptFilesFilter = ".png,.jpeg,.jpg";
                    model.private = true;
                    break;
            }

            return model;
        },

        getAwsData: function getAwsData(callingScope) {
            var deferred = this.$q.defer();

            if (this.Gon) {
                if (!this.Gon.aws_policy_expiration || this.moment().isSameOrAfter(this.moment(this.Gon.aws_policy_expiration))) {
                    this.AnalyticsService.track(callingScope, this.AnalyticsService.analytics_events.getting_aws_policy);

                    if (this.gettingNewAWSPolicyPromise) {
                        this.gettingNewAWSPolicyPromise
                            .then(function successHandler() {
                                deferred.resolve(true);
                            })
                            .catch(function errorHandler() {
                                this.AnalyticsService.trackError(callingScope, this.AnalyticsService.analytics_events.getting_aws_policy);
                                deferred.reject(new Error('gettingNewAWSPolicyPromise'));
                            }.bind(this));
                    } else {

                        this.gettingNewAWSPolicyPromise = this.APIService.fetch(this.Routes.get_aws_policy_path())
                            .then(function getAWSPolicySuccess(res) {
                                this.Gon.aws_policy = res.data.policy;
                                this.Gon.aws_signature = res.data.signature;
                                this.Gon.aws_private_policy = res.data.private_policy;
                                this.Gon.aws_private_signature = res.data.private_signature;
                                this.Gon.aws_policy_expiration = res.data.expiration;
                                this.Gon.aws_access_key = res.data.aws_access_key;

                                //clear this reference to prepare it for the next call.
                                this.gettingNewAWSPolicyPromise = null;

                                deferred.resolve(true);
                            }.bind(this))
                            .catch(function errorHandler(error) {
                                this.AnalyticsService.trackError(callingScope, this.AnalyticsService.analytics_events.getting_aws_policy, error);
                                deferred.reject(new Error('gettingNewAWSPolicyPromise'));
                            }.bind(this));
                    }
                } else {
                    deferred.resolve(true);
                }
            }

            return deferred.promise;
        },


        uploadPhoto: function uploadPhoto(files, model, callingScope, forceUploadToS3, inlineDisposition = false) {
            var awsPolicyPromise = null;
            if (forceUploadToS3) {
                awsPolicyPromise = this.getAwsData(callingScope);
            }
            //create a promise for each one of the files that can already be used be the caller.
            //this promise can be used to get the progress and also for aborting the $uploadupload of each file.
            files.forEach(function (file) {
                if (!awsPolicyPromise && !this.isImage(file)) {
                    awsPolicyPromise = this.getAwsData(callingScope);
                }
                file.progress = 0;
                file.uploadAPI = {
                    progress: function progress(callbackFunction) {
                        this.progressCallbackFunction = callbackFunction;
                        if (angular.isFunction(this.progressCallbackFunction)) {
                            this.progressCallbackFunction(file.progress);
                        }
                    },
                    abort: function abort() {
                        if (file.uploadPromise) {
                            file.uploadPromise.abort();
                        } else {
                            this.pendingAbort = true;
                        }
                    },
                    finished: function finished(callbackFunction) {
                        this.finishedCallback = callbackFunction;
                    },
                    _callFinished: function _callFinished() {
                        if (angular.isFunction(this.finishedCallback)) {
                            this.finishedCallback();
                        }
                    }

                };
            }.bind(this));

            // always resolve when aws policy is not required
            if (!awsPolicyPromise) {
                awsPolicyPromise = Promise.resolve();
            }
            return awsPolicyPromise.then(function () {
                return this.uploadPhotoInternal(files, model, callingScope, forceUploadToS3, inlineDisposition);
            }.bind(this));
        },

        isImage: function isImage(file) {
            return file && file.type && file.type.indexOf('image') > -1;
        },

        uploadPhotoInternal: function uploadPhotoInternal(files, model, callingScope, forceUploadToS3, inlineDisposition = false) {
            var self = this;

            var deferred = this.$q.defer();

            if (!files || !files.length) {
                return;
            }

            var counter = files.length;

            for (var fileIndex = 0; fileIndex < files.length; fileIndex++) {

                (function (fileIndex) { //Immediately-Invoked Function Expression, in order to resolve closure issue
                    var file = files[fileIndex];

                    var analyticsArgs = {
                        file_type: file.type,
                        file_size: file.size,
                        file_index: fileIndex,
                        total_files: files.length,
                        file_name: file.name,
                        new_file_name: file.name.replace(/[^A-Z0-9.-]+/ig, "_")
                    };
                    self.AnalyticsService.track(callingScope, self.AnalyticsService.analytics_events.uploading_single_file, analyticsArgs);

                    // Replace all non alphanumeric characters as it causes issues with Cloudinary upload (file name html encoded)
                    // The next statement creates new attribute on file object - ngfName - but it is indeed used by following Upload method
                    self.Upload.rename(file, file.name.replace(/[^A-Z0-9.-]+/ig, "_"));

                    var fileUploadPromise;
                    if (!forceUploadToS3 && self.isImage(file)) { // detect image upload
                        var cloudName = self.CloudinaryConfigService.getCloudinaryCloudName(self.CloudinaryConfigService.CONFIG_TYPE.USER_UPLOAD);
                        fileUploadPromise = self.Upload.upload({
                            url: 'https://api.cloudinary.com/v1_1/' + cloudName + '/upload',
                            method: 'POST',
                            data: {
                                upload_preset: self.CloudinaryConfigService.getCloudinaryUploadPreset(self.CloudinaryConfigService.CONFIG_TYPE.USER_UPLOAD),
                                file: file,
                                folder: model.key.replace("${filename}", '')
                            },
                            file: file
                        });
                    } else {
                        var model_private_acl = model.private != null && model.private === true;
                        var contentDisposition = (inlineDisposition ? 'inline; filename=' : 'attachment; filename=') + file.name.replace(/[^A-Z0-9.-]+/ig, "_");
                        fileUploadPromise = self.Upload.upload({
                            url: "https://s3-us-west-2.amazonaws.com/" + self.Gon.aws_bucket, //S3 upload url including bucket name
                            method: 'POST',
                            fields: {
                                utf8: true,
                                "Content-Type": model.contentType ? model.contentType : '', // content type of the file (NotEmpty)
                                acl: model_private_acl ? 'private' : 'public-read', // sets the access to the uploaded file in the bucket: private or public depending on file model type
                                key: model.key, // the key to store the file on S3, could be file name or customized
                                AWSAccessKeyId: self.Gon.aws_access_key,
                                "Content-Disposition": contentDisposition,
                                policy: model_private_acl ? self.Gon.aws_private_policy : self.Gon.aws_policy, // base64-encoded json policy (see article below)
                                signature: model_private_acl ? self.Gon.aws_private_signature : self.Gon.aws_signature, // base64-encoded signature based on policy string (see article below)
                                success_action_status: '201',
                                "X-Requested-With": 'xhr'
                            },
                            file: file
                        });
                    }

                    fileUploadPromise.progress(function progressHandler(evt) {
                        evt.config.file.progress = parseInt(100.0 * evt.loaded / evt.total);
                        if (angular.isFunction(file.uploadAPI.progressCallbackFunction)) {
                            if (model.dontUpdateServer) {
                                // if the update to the model on the server side is managed outside of this service, just return the progress for the upload to s3
                                file.uploadAPI.progressCallbackFunction(evt.config.file.progress);
                            } else {
                                // if the update will be managed here, we want to set the 100% when all is done, including the update to the model so we save the last 5% to this
                                file.uploadAPI.progressCallbackFunction(parseInt(0.95 * evt.config.file.progress));
                            }
                        }
                    }).success(function successHandler(data, status, headers, config) {
                        var s3Response;
                        if (!forceUploadToS3 && self.isImage(file)) {
                            s3Response = self._getSavePhotoArguments(file, data, model);
                        } else {
                            s3Response = self._getSaveAssetArguments(file, data, model);
                        }

                        if (model.dontUpdateServer) {
                            //allow calling function to handle server update later
                            file["s3Response"] = s3Response;
                            file["uploaded"] = true;

                            file.uploadAPI._callFinished();

                            // if last file we need to return the success
                            if (--counter === 0) {
                                deferred.resolve(true);
                            }
                        } else {
                            // We are not waiting for the return value because the modal window will already be closed
                            file['fileModelUpdatePromise'] = self.apiCreate(model.saveURL, s3Response, model.instance, true)
                                .then(
                                function success() {
                                    // if we are updating the model too, only now we can set it as 100%
                                    if (angular.isFunction(file.uploadAPI.progressCallbackFunction)) {
                                        file.uploadAPI.progressCallbackFunction(100);
                                    }

                                    file.uploadAPI._callFinished();

                                    // if last file we need to return the success
                                    if (--counter === 0) {
                                        deferred.resolve(true);
                                    }
                                },
                                function error(resp) {
                                    analyticsArgs = {
                                        error_message: resp.data ? resp.data.error_message : '',
                                        error_type: resp.data ? resp.data.error_type : '',
                                        total_files: files.length
                                    };
                                    self.AnalyticsService.track(callingScope, self.AnalyticsService.analytics_events.error_uploading_single_file, analyticsArgs);
                                    deferred.reject(data);
                                }
                            );
                        }

                    }).error(function errorHandler(data, status, headers, config) {
                        var analyticsArgs;
                        if (angular.isString(data)) {
                            analyticsArgs = {
                                errorFromAmazon: data
                            };
                        } else if (angular.isObject(data)) {
                            analyticsArgs = data;
                        }

                        self.DatadogRUMService.addError(new Error("Error in uploading files to cloudinary", {cause: data}), {
                            status: status,
                        });

                        self.AnalyticsService.track(callingScope, self.AnalyticsService.analytics_events.error_uploading_single_file_to_s3, analyticsArgs);
                        deferred.reject(data);
                    });

                    //add the promise of this upload on the file object so it can be stopped if needed
                    file['uploadPromise'] = fileUploadPromise;

                    if (file.uploadAPI.pendingAbort) {
                        // the time out is need to get the upload starting and only then the abort will work.
                        // calling the abort now to allow the same caller behavior as if it was called when
                        // we did have a valid AWS policy
                        self.$timeout(function () {
                            //do not start the upload for this file
                            file['uploadPromise'].abort();
                        }, 100);
                    } else {
                        file["uploadPromise"].then(function(res) {
                            file["uploadPromise"] = { data: res.data }
                        }) 
                    }
                })(fileIndex);
            }

            return deferred.promise;
        }
    });
}());

